<template>
  <div>
    <ApiError :errors="errors"></ApiError>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :server-items-length="total"
      :options.sync="options"
      :hide-default-footer="hideFooter"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="customReport">{{ customReport.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="customReport">
            <span v-for="(customReportFilter, index) in customReportFilters" :key="customReportFilter.id">
              <v-chip v-if="!isEmpty(customReportFilter.value)" class="mx-1" close @click:close="onCloseFilter(index)">
                <span>{{ customReportFilter.name }}</span
                >&nbsp;<strong>{{ customReportFilter.filterType.matchingName }}</strong> &nbsp;<span
                  >({{ displayFilterValue(customReportFilter) }})</span
                >
              </v-chip>
            </span>
          </div>
          <v-tooltip bottom v-if="customReport && customReportFilters && customReportFilters.length > 0 && showFilters">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                fab
                small
                @click="onCloseFilters()"
                v-bind="attrs"
                v-on="on"
                :disabled="isGeneratingReport"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Hide Filters") }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-else-if="customReport && customReportFilters && customReportFilters.length > 0 && !showFilters"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                fab
                small
                @click="onOpenFilters()"
                v-bind="attrs"
                v-on="on"
                :disabled="isGeneratingReport"
              >
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Show Filters") }}</span>
          </v-tooltip>
          <GenerationFileType
            :isGeneratingReport="isGeneratingReport"
            :itemsLength="items.length"
            @generateReport="onGenerateReport"
          />
        </v-toolbar>
        <v-card v-if="showFilters" elevation="10">
          <v-toolbar flat>
            <v-toolbar-title>
              <span>Apply Filters</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="onCloseFilters()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Close") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" @submit.prevent="onSubmit">
              <v-simple-table dense v-if="customReport">
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(customReportFilter, index) in customReportFilters" :key="customReportFilter.id">
                      <th width="0%" class="text-right">
                        {{ customReportFilter.name }}
                        {{ customReportFilter.filterType.matchingName }}
                      </th>
                      <td width="60%" class="pa-3">
                        <CustomReportFilterFormField
                          :key="refresh"
                          outlined
                          dense
                          v-model="customReportFilters[index]"
                          clearable
                          :disabled="isGeneratingReport"
                          hide-details
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center py-5">
                <v-btn type="submit" small color="primary" @click="onSubmit">Apply</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:no-data>
        <p class="mt-5">
          No report data found
        </p>
        <p v-if="hasFilters">Refine your filters to see more data</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Vue from "vue";
import CustomReportFilterFormField from "@/gapp-components/components/fields/CustomReportFilterFormField.vue";
import ApiError from "@/gapp-components/components/display/ApiError";
import ApiService from "@/gapp-components/services/api.service";
import GenerationFileType from "@/gapp-components/components/tables/customReports/GenerationFileType.vue";

export default {
  name: "CustomReportTable",
  props: {
    customReportId: Number
  },
  components: {
    ApiError,
    CustomReportFilterFormField,
    GenerationFileType
  },
  data: function() {
    return {
      valid: true,
      refresh: 0,
      isLoading: true,
      showFilters: false,
      errors: {},
      customReport: undefined,
      headers: [],
      items: [],
      isGeneratingReport: false,
      errorMessage: "",
      total: 0,
      options: {
        itemsPerPage: 10
      },
      hideFooter: true,
      filters: [],
      customReportFilters: []
    };
  },

  watch: {
    customReportId() {
      this.fetchCustomReport();
    },
    options: {
      handler() {
        this.fetchCustomReport();
      },
      deep: true
    }
  },

  mounted() {
    this.fetchCustomReport();
  },

  methods: {
    applyFilters() {
      this.filters = this.customReportFilters
        .map(filter => {
          if (filter.value && filter.value.id) {
            return { id: filter.id, value: filter.value.id };
          } else {
            return { id: filter.id, value: filter.value };
          }
        })
        .filter(filter => filter.value != null && filter.value != undefined);
    },
    onSubmit() {
      this.applyFilters();
      this.onCloseFilters();
      this.fetchCustomReport();
    },
    onCloseFilter(index) {
      Vue.set(this.customReportFilters[index], "value", null);
      this.refresh++;
      this.onSubmit();
    },
    onOpenFilters() {
      this.showFilters = true;
      this.refresh++;
    },
    onCloseFilters() {
      this.showFilters = false;
    },
    onGenerateReport(generationType) {
      this.errors = {};
      this.isLoading = true;
      this.isGeneratingReport = true;
      this.applyFilters();
      ApiService.post(`/api/customReports/generate/${this.customReportId}/${generationType}`, {
        customReportFilters: this.filters
      })
        .then(() => {
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          this.isGeneratingReport = false;
          if (error.response && error.response.status === 404) {
            this.errorMessage = "Report generation failed. The specified report was not found.";
          } else {
            this.errorMessage = "An unexpected error occurred while generating the report. Please try again.";
          }
        })
        .finally(() => {
          this.isGeneratingReport = false;
          this.loading = false;
        });
    },
    hasFilters() {
      return (
        this.customReportFilters.filter(
          filter => filter.value != null && filter.value != undefined && filter.value.trim().length > 0
        ).length > 0
      );
    },
    displayFilterValue(filter) {
      let displayValue = "";
      if (filter && this.isObject(filter.value) && filter.value.id) {
        if (filter.filterType.objectKey && filter.value[filter.filterType.objectKey]) {
          displayValue += filter.value[filter.filterType.objectKey];
        }
        if (displayValue.length == 0) {
          displayValue += filter.value.id;
        }
      } else if (
        filter &&
        filter.filterType &&
        filter.filterType.name.startsWith("DATE") &&
        moment(filter.value).isValid()
      ) {
        displayValue = this.$util.formatDateClient(filter.value, "MM/DD/YYYY hh:mm a z", this.selectedClient);
      } else {
        displayValue = filter.value;
      }
      return displayValue;
    },
    isObject(value) {
      return (typeof value === "object" || typeof value === "function") && value !== null;
    },
    isEmpty(value) {
      if (typeof value === "string") {
        return value === null || value === undefined || value.trim().length == 0;
      }
      return value === null || value === undefined;
    },
    fetchHeadersAndFilters() {
      if (!this.customReport) {
        return;
      }
      if (this.headers.length == 0 || this.customReportFilters.length == 0) {
        this.headers = this.customReport.customReportHeaders.map(customReportHeader => {
          return {
            text: customReportHeader.name,
            value: customReportHeader.name,
            sortable: false,
            align:
              customReportHeader.alignment && customReportHeader.alignment.name
                ? customReportHeader.alignment.name.toLowerCase()
                : "left"
          };
        });
        this.customReportFilters = this.customReport.customReportFilters;
      }
    },
    fetchCustomReport() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (this.customReportId && this.customReportId > 0) {
        this.errors = {};
        this.isLoading = true;
        return ApiService.post(
          "/api/customReports/view/" +
            this.customReportId +
            "?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          {
            customReportFilters: this.filters
          }
        )
          .then(({ data }) => {
            this.customReport = data.customReport;
            this.hideFooter = !this.customReport.paging;
            this.items = data.content;
            this.total = data.totalElements;
            this.$emit("total", this.total);

            this.fetchHeadersAndFilters();

            this.$emit("name", this.customReport.name);
          })
          .catch(error => {
            this.errors = ApiService.getErrorsFromResponse(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        return Promise.reject("No custom report id provided");
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient"])
  }
};
</script>
