<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          fab
          small
          v-bind="attrs"
          v-on="on"
          @click="openDialog"
          :disabled="isGeneratingReport || itemsLength == 0"
        >
          <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
          <v-progress-circular indeterminate color="primary" v-else />
        </v-btn>
      </template>
      <span>{{ $i18n.translate("Export Report") }}</span>
    </v-tooltip>

    <v-dialog v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title>{{ $i18n.translate("Select Export Format") }}</v-card-title>
        <v-card-text>
          <v-alert v-if="errorMessage" type="error" dense>
            {{ errorMessage }}
          </v-alert>
          <v-radio-group v-if="!errorMessage" v-model="selectedGenerationType">
            <v-radio v-for="type in generationTypes" :key="type.name" :label="type.name" :value="type.name" />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = false">{{ $i18n.translate("Cancel") }}</v-btn>
          <v-btn text @click="onGenerate" :disabled="!selectedGenerationType || errorMessage.length > 0">
            {{ $i18n.translate("Generate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "GenerationFileType",
  props: {
    isGeneratingReport: {
      type: Boolean,
      default: false
    },
    itemsLength: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showDialog: false,
      generationTypes: [],
      selectedGenerationType: "CSV",
      errorMessage: ""
    };
  },
  mounted() {
    this.fetchGenerationTypes();
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    fetchGenerationTypes() {
      ApiService.get("/api/types/generationType")
        .then(response => {
          this.generationTypes = response.data;
          if (this.generationTypes.length === 0) {
            this.errorMessage = "No generation types available at the moment.";
          } else {
            const csvType = this.generationTypes.find(type => type.name === "CSV");
            if (csvType) {
              this.selectedGenerationType = csvType.name;
            } else {
              this.selectedGenerationType = this.generationTypes[0].name;
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.errorMessage = "Failed to load generation types. Please try again later.";
          } else {
            this.errorMessage = "An unexpected error occurred. Please try again.";
          }
        });
    },
    onGenerate() {
      if (!this.selectedGenerationType) {
        this.errorMessage = "Please select an export format.";
        return;
      }

      this.dialog = false;
      this.$emit("generateReport", this.selectedGenerationType);
    }
  }
};
</script>
