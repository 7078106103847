var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApiError',{attrs:{"errors":_vm.errors}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"server-items-length":_vm.total,"options":_vm.options,"hide-default-footer":_vm.hideFooter},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(_vm.customReport)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.customReport.name))]):_vm._e(),_c('v-spacer'),(_vm.customReport)?_c('div',_vm._l((_vm.customReportFilters),function(customReportFilter,index){return _c('span',{key:customReportFilter.id},[(!_vm.isEmpty(customReportFilter.value))?_c('v-chip',{staticClass:"mx-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.onCloseFilter(index)}}},[_c('span',[_vm._v(_vm._s(customReportFilter.name))]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(customReportFilter.filterType.matchingName))]),_vm._v("  "),_c('span',[_vm._v("("+_vm._s(_vm.displayFilterValue(customReportFilter))+")")])]):_vm._e()],1)}),0):_vm._e(),(_vm.customReport && _vm.customReportFilters && _vm.customReportFilters.length > 0 && _vm.showFilters)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","small":"","disabled":_vm.isGeneratingReport},on:{"click":function($event){return _vm.onCloseFilters()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,1887502320)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Hide Filters")))])]):(_vm.customReport && _vm.customReportFilters && _vm.customReportFilters.length > 0 && !_vm.showFilters)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","small":"","disabled":_vm.isGeneratingReport},on:{"click":function($event){return _vm.onOpenFilters()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Show Filters")))])]):_vm._e(),_c('GenerationFileType',{attrs:{"isGeneratingReport":_vm.isGeneratingReport,"itemsLength":_vm.items.length},on:{"generateReport":_vm.onGenerateReport}})],1),(_vm.showFilters)?_c('v-card',{attrs:{"elevation":"10"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v("Apply Filters")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.onCloseFilters()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1428231117)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Close")))])])],1),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.customReport)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.customReportFilters),function(customReportFilter,index){return _c('tr',{key:customReportFilter.id},[_c('th',{staticClass:"text-right",attrs:{"width":"0%"}},[_vm._v(" "+_vm._s(customReportFilter.name)+" "+_vm._s(customReportFilter.filterType.matchingName)+" ")]),_c('td',{staticClass:"pa-3",attrs:{"width":"60%"}},[_c('CustomReportFilterFormField',{key:_vm.refresh,attrs:{"outlined":"","dense":"","clearable":"","disabled":_vm.isGeneratingReport,"hide-details":""},model:{value:(_vm.customReportFilters[index]),callback:function ($$v) {_vm.$set(_vm.customReportFilters, index, $$v)},expression:"customReportFilters[index]"}})],1)])}),0)]},proxy:true}],null,false,1732722110)}):_vm._e(),_c('div',{staticClass:"text-center py-5"},[_c('v-btn',{attrs:{"type":"submit","small":"","color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Apply")])],1)],1)],1)],1):_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mt-5"},[_vm._v(" No report data found ")]),(_vm.hasFilters)?_c('p',[_vm._v("Refine your filters to see more data")]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }