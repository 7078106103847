<template>
  <CustomReportTable v-if="customReportId" :customReportId="customReportId" @name="setName"> </CustomReportTable>
</template>

<script>
import CustomReportTable from "../../gapp-components/components/tables/CustomReportTable.vue";
export default {
  components: { CustomReportTable },
  name: "CustomReport",
  data() {
    return {
      customReportId: undefined,
      reportName: undefined
    };
  },
  methods: {
    setName(name) {
      this.reportName = name;
      this.breadcrumbs();
    },
    breadcrumbs() {
      this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        {
          text: "Reports"
        },
        { text: this.reportName ? this.reportName : "Report" }
      ]);
    }
  },
  mounted() {
    this.customReportId = parseInt(this.$route.params.id);
    this.breadcrumbs();
  }
};
</script>
